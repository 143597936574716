var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "van-main has-fixed-btn" },
    [
      _c(
        "div",
        { staticClass: "van-common-detail" },
        [
          _c(
            "van-tabs",
            {
              model: {
                value: _vm.activeName,
                callback: function($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName"
              }
            },
            [
              _c(
                "van-tab",
                { attrs: { name: "info", title: "报关行信息" } },
                [
                  _c(
                    "van-cell-group",
                    { attrs: { title: "企业信息" } },
                    [
                      _c("van-cell", {
                        attrs: {
                          title: "企业全称",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.funderdetail.orgName
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "统一社会信用代码",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.funderdetail.orgNo
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "企业简称",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.funderdetail.orgShortName
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "注册资本（万元）",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.funderdetail.orgRegCap
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "成立日期",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.funderdetail.establishDate
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "企业联系人",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.funderdetail.enterpriseContact
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "联系电话",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.funderdetail.orgContactPhone
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "服务口岸",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.funderdetail.servicePort
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "注册地址",
                          value: _vm.utils.newProvinceCityFormatter([
                            _vm.funderdetail.orgRegProvince,
                            _vm.funderdetail.orgRegCity,
                            _vm.funderdetail.orgRegXq,
                            _vm.funderdetail.orgRegDetail
                          ])
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "办公地址",
                          value: _vm.utils.newProvinceCityFormatter([
                            _vm.funderdetail.orgWorkProvince,
                            _vm.funderdetail.orgWorkCity,
                            _vm.funderdetail.orgWorkXq,
                            _vm.funderdetail.orgWorkDetail
                          ])
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "公司简介",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.funderdetail.companyProfile
                          )
                        }
                      }),
                      _c(
                        "van-cell",
                        { attrs: { title: "企业营业执照" } },
                        [
                          _c("van-uploader", {
                            attrs: {
                              "show-upload": false,
                              deletable: false,
                              disabled: ""
                            },
                            on: { "click-preview": _vm.preview },
                            model: {
                              value: _vm.businessLicenseList,
                              callback: function($$v) {
                                _vm.businessLicenseList = $$v
                              },
                              expression: "businessLicenseList"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "van-cell-group",
                    { attrs: { title: "企业法定代表人信息" } },
                    [
                      _c("van-cell", {
                        attrs: {
                          title: "法定代表人姓名",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.funderdetail.businessName
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "法定代表人联系电话",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.funderdetail.bizPhone
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "证件类型",
                          value: _vm.utils.statusFormat(
                            _vm.funderdetail.businessSnType,
                            "nongtoutypeCertificateType"
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "证件号码",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.funderdetail.businessSn
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "电子邮箱",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.funderdetail.businessEmail
                          )
                        }
                      }),
                      _c(
                        "van-cell",
                        {
                          staticClass: "certificate-photo",
                          attrs: { title: "证件照片" }
                        },
                        [
                          _c("van-uploader", {
                            attrs: {
                              "show-upload": false,
                              deletable: false,
                              disabled: ""
                            },
                            on: { "click-preview": _vm.preview },
                            model: {
                              value: _vm.fileBusinessImgsList,
                              callback: function($$v) {
                                _vm.fileBusinessImgsList = $$v
                              },
                              expression: "fileBusinessImgsList"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "van-cell-group",
                    { attrs: { title: "资质及证明" } },
                    [
                      _c(
                        "van-cell",
                        {
                          staticClass: "certificate-photo",
                          attrs: { title: "图片列表" }
                        },
                        [
                          _c("van-uploader", {
                            attrs: {
                              "show-upload": false,
                              deletable: false,
                              disabled: ""
                            },
                            on: { "click-preview": _vm.preview },
                            model: {
                              value: _vm.selectedOptionsList,
                              callback: function($$v) {
                                _vm.selectedOptionsList = $$v
                              },
                              expression: "selectedOptionsList"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "van-tab",
                { attrs: { name: "delivery", title: "报价方案" } },
                [
                  _c("div", { staticClass: "table goods-table" }, [
                    _c("table", { staticClass: "inner-goods-table" }, [
                      _c("thead", [
                        _c("tr", [
                          _c("th", [_vm._v("费用类型")]),
                          _c("th", [_vm._v("收费标准")]),
                          _c("th", [_vm._v("备注")])
                        ])
                      ]),
                      _c(
                        "tbody",
                        _vm._l(_vm.accountInfoVoList, function(item) {
                          return _c("tr", { key: item.id }, [
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.utils.isEffectiveCommon(item.expenseType)
                                )
                              )
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.utils.isEffectiveCommon(
                                    item.chargingStandard
                                  )
                                )
                              )
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(_vm.utils.isEffectiveCommon(item.remark))
                              )
                            ])
                          ])
                        }),
                        0
                      )
                    ])
                  ])
                ]
              ),
              _c(
                "van-tab",
                { attrs: { name: "audit", title: "审批意见" } },
                [
                  Number(_vm.$route.query.taskViewMode) !== 0
                    ? _c("workflow-audit", {
                        attrs: {
                          "page-key": "customs_broker_add",
                          "business-key": _vm.funderdetail.enterpriseId,
                          "proc-inst-id": _vm.$route.query.procInstId,
                          variable: {
                            businessId: _vm.$route.query.id,
                            motif: _vm.funderdetail.orgShortName
                          },
                          "view-mode": true
                        },
                        on: {
                          confirmAudit: function($event) {
                            return _vm.utils.backReturn(
                              _vm.$route.query.messageType
                            )
                          }
                        }
                      })
                    : _c("workflow-audit", {
                        attrs: {
                          "page-key": "customs_broker_add",
                          "business-key": _vm.funderdetail.enterpriseId,
                          "task-id": _vm.$route.query.taskId,
                          "proc-inst-id": _vm.$route.query.procInstId,
                          variable: {
                            businessId: _vm.$route.query.id,
                            motif: _vm.funderdetail.orgShortName
                          }
                        },
                        on: {
                          confirmAudit: function($event) {
                            return _vm.utils.backReturn(
                              _vm.$route.query.messageType
                            )
                          }
                        }
                      })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "van-row",
        { staticClass: "fixed-btn-bottom", attrs: { gutter: "20" } },
        [
          _c("van-button", {
            attrs: { type: "info", color: "#1373CC", block: "", text: "返回" },
            on: {
              click: function($event) {
                return _vm.utils.backReturn(_vm.$route.query.messageType)
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }